














import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class CheckboxForm extends Vue {
  @Prop({ default: false }) value!: boolean

  onStatusChange(val: boolean) {
    this.$emit('onStatusChange', val)
  }
}
